import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/nedix/projects/internal/nedix.io/git_modules/gatsby-theme-carbon/packages/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Setup`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-yaml",
        "metastring": "path=docker-compose-spx.yml",
        "path": "docker-compose-spx.yml"
      }}>{`version: "3.8"

services:
  nginx-spx:
    image: wodby/nginx:1.19
    environment:
      NGINX_VHOST_PRESET: php
      NGINX_BACKEND_HOST: spx
      NGINX_SERVER_ROOT: /var/www/html/public
    ports:
      - 8000:80
    volumes:
      - app:/var/www/html

  spx:
    image: ghcr.io/nedix/spx-docker:8.2
    volumes:
      - app:/var/www/html
    depends_on:
      - nginx-spx

volumes:
  app:
    driver: local
    driver_opts:
      device: \${PWD}
      o: bind
      type: none
`}</code></pre>
    <h2>{`Usage`}</h2>
    <h3>{`Step 1.`}</h3>
    <p>{`Start the containers:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`docker compose -f docker-compose.yml -f docker-compose-spx.yml up -d
`}</code></pre>
    <h3>{`Step 2.`}</h3>
    <p>{`Navigate to your page on `}<a parentName="p" {...{
        "href": "http://localhost:8000"
      }}>{`localhost:8000`}</a>{` to capture the profiling data.`}</p>
    <h3>{`Step 3.`}</h3>
    <p>{`Find SPX on `}<a parentName="p" {...{
        "href": "http://localhost:8000/?SPX_UI_URI=/&SPX_KEY=dev"
      }}>{`localhost:8000/?SPX_UI_URI=/&SPX_KEY=dev`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      